import {Text, View} from '@react-pdf/renderer'
// import {Dicionario} from '../../../../utils/Dicionario'
import {PhonesExtended} from './PhonesExtended'
import {dadosTabelas} from '../../../../pages/pessoas/components/dadosTabelas'
import {EmailsExtended} from './EmailsExtended'
import {AddressesExtended} from './AddressesExtended'
import {BasicData} from './BasicaData'
import {styles} from '../style'
import {RelatedPeopleEmails} from './RelatedPeopleEmails'
import {RelatedPeoplePhones} from './RelatedPeoplePhones'
import {RelatedPeople} from './RelatedPeople'

export const Ph3aData = ({resultPeapleData, result, typeDoc}) => {
  let basicdata = null
  if (result.basic_data) {
    if (typeof result.basic_data['Result'] !== 'undefined') {
      basicdata = {...result.basic_data['Result']}
    } else {
      basicdata = {...result.basic_data}
    }
  }
  function statusPhones(status) {
    switch (status) {
      case 1:
        return 'Ativo'
        break
      case 2:
        return 'Verificado'
        break
      case 3:
        return 'Hot'
        break
    }
  }
  return (
    <>
      <View style={[styles.row]}>
        <Text style={[styles.subtituloDestaque]}>{result.nome}</Text>
      </View>
      {resultPeapleData ? (
        resultPeapleData.DocumentType === 0 ? (
          <>
            <View style={styles.row}>
              <View style={styles.col6}>
                <View style={styles.groupInfo}>
                  <Text style={[styles.h4, styles.bold]}>
                    Nome:
                    <Text style={styles.h4}>{resultPeapleData.Name}</Text>
                  </Text>
                </View>
              </View>
            </View>
            <View style={styles.row}>
              <View style={styles.col6}>
                <View style={styles.groupInfo}>
                  <Text style={[styles.h4, styles.bold]}>
                    Idade:
                    <Text style={styles.h4}>{resultPeapleData.Age}</Text>
                  </Text>
                </View>
              </View>
            </View>
            <View style={styles.row}>
              <View style={styles.col6}>
                <View style={styles.groupInfo}>
                  <Text style={[styles.h4, styles.bold]}>
                    Documento:
                    <Text style={styles.h4}>{resultPeapleData.DocumentFormatted}</Text>
                  </Text>
                </View>
              </View>
            </View>
            <View style={styles.row}>
              <View style={styles.col6}>
                <View style={styles.groupInfo}>
                  <Text style={[styles.h4, styles.bold]}>
                    Mãe:
                    <Text style={styles.h4}>{resultPeapleData.Person.MotherName}</Text>
                  </Text>
                </View>
              </View>
            </View>
            {/* <View style={styles.row}>
              <View style={styles.col6}>
                <View style={styles.groupInfo}>
                  <Text style={[styles.h4, styles.bold]}>
                    Renda:
                    <Text style={styles.h4}>{resultPeapleData.Income.Presumed}</Text>
                  </Text>
                </View>
              </View>
            </View> */}
            {basicdata ? (
              <View style={styles.row}>
                <View style={styles.col6}>
                  <View style={styles.groupInfo}>
                    <Text style={[styles.h4, styles.bold]}>
                      Indícios de Óbito:
                      <Text style={styles.h4}>
                        {' '}
                        {basicdata[0]['BasicData']['HasObitIndication'] ? 'Sim' : 'Não'}
                      </Text>
                    </Text>
                  </View>
                </View>
              </View>
            ) : (
              <></>
            )}
            {/* <p className='fs-6'>Nome: {resultPeapleData.Name} </p>
            <p className='fs-6'>Idade: {resultPeapleData.Age} </p>
            <p className='fs-6'>Documento: {resultPeapleData.DocumentFormatted} </p>
            <p className='fs-6'>Mãe: {resultPeapleData.Person.MotherName} </p>
            <p className='fs-6'>Renda: {resultPeapleData.Income.Presumed} </p> */}
          </>
        ) : (
          <>
            <View style={styles.row}>
              <View style={styles.col6}>
                <View style={styles.groupInfo}>
                  <Text style={[styles.h4, styles.bold]}>
                    Nome:
                    <Text style={styles.h4}>{resultPeapleData.Name}</Text>
                  </Text>
                </View>
              </View>

              <View style={styles.col6}>
                <View style={styles.groupInfo}>
                  <Text style={[styles.h4, styles.bold]}>
                    Idade:
                    <Text style={styles.h4}>{resultPeapleData.Age}</Text>
                  </Text>
                </View>
              </View>

              <View style={styles.col6}>
                <View style={styles.groupInfo}>
                  <Text style={[styles.h4, styles.bold]}>
                    Documento:
                    <Text style={styles.h4}>{resultPeapleData.DocumentFormatted}</Text>
                  </Text>
                </View>
              </View>

              {/* <View style={styles.col6}>
                <View style={styles.groupInfo}>
                  <Text style={[styles.h4, styles.bold]}>
                    Renda:
                    <Text style={styles.h4}>{resultPeapleData.Income.Presumed}</Text>
                  </Text>
                </View>
              </View> */}

              {/* <p className='fs-6'>Nome: {resultPeapleData.Name} </p>
            <p className='fs-6'>Idade: {resultPeapleData.Age} </p>
            <p className='fs-6'>Documento: {resultPeapleData.DocumentFormatted} </p> */}
              {/* <p className="fs-6">Renda: {resultPeapleData.Income.Presumed} </p> */}
            </View>
          </>
        )
      ) : (
        <>
          <BasicData data={basicdata} estrutura={dadosTabelas[typeDoc]['BasicData']}></BasicData>
        </>
      )}

      {/* { resultPeapleData ||   result?.phones_extended ?  */}
      <View style={[styles.row, {paddingTop: 15, paddingBottom: 10}]}>
        <Text style={styles.subtitulo}>Telefones</Text>
      </View>
      {resultPeapleData ? (
        resultPeapleData.Phones && resultPeapleData.Phones.length > 0 ? (
          <>
            {resultPeapleData.Phones.map((phones, index) => {
              if (phones.Status >= 1) {
                return (
                  <View key={index}>
                    <Text style={[styles.space, styles.h4, styles.bold]}>
                      Telefone: {phones.FormattedNumber} - Whatsapp:{' '}
                      {phones.IsWhatsapp ? 'Sim' : 'Não'}
                      {'  '}(Status: {statusPhones(phones.Status)})
                    </Text>
                  </View>
                )
              }
            })}
          </>
        ) : (
          <></>
        )
      ) : (
        <></>
      )}
      {result?.phones_extended ? (
        <PhonesExtended
          data={result.phones_extended}
          estrutura={dadosTabelas[typeDoc]['PhonesExtended']}
        ></PhonesExtended>
      ) : (
        ''
      )}
      {/* // : <></>} */}

      {resultPeapleData && (resultPeapleData.Emails || result.emails_extended) ? (
        <View style={[styles.row, {paddingTop: 15, paddingBottom: 10}]}>
          <Text style={styles.subtitulo}>E-mails</Text>
        </View>
      ) : (
        <></>
      )}

      {resultPeapleData ? (
        resultPeapleData.Emails && resultPeapleData.Emails.length > 0 ? (
          <>
            {resultPeapleData.Emails.map((emails, index) => {
              return (
                <View key={index}>
                  <Text style={[styles.space, styles.h4, styles.bold]}>
                    <Text style={[styles.h4, styles.bold]}>E-mail: {emails.Email}</Text>
                  </Text>
                </View>
              )
            })}
          </>
        ) : (
          <></>
        )
      ) : (
        <></>
      )}
      {result?.emails_extended ? (
        <EmailsExtended
          data={result.emails_extended}
          estrutura={dadosTabelas[typeDoc]['EmailsExtended']}
        ></EmailsExtended>
      ) : (
        ''
      )}

      {result.related_people ? (
        <RelatedPeople
          data={result.related_people}
          estrutura={dadosTabelas[typeDoc]['RelatedPeople']}
        ></RelatedPeople>
      ) : (
        ''
      )}

      {result.related_people_emails ? (
        <RelatedPeopleEmails
          data={result.related_people_emails}
          estrutura={dadosTabelas[typeDoc]['RelatedPeopleEmails']}
        ></RelatedPeopleEmails>
      ) : (
        ''
      )}

      {result.related_people_phones ? (
        <RelatedPeoplePhones
          data={result.related_people_phones}
          estrutura={dadosTabelas[typeDoc]['RelatedPeoplePhones']}
        ></RelatedPeoplePhones>
      ) : (
        ''
      )}
      <View style={[styles.row, {paddingTop: 15, paddingBottom: 10}]}>
        <Text style={[styles.subtitulo]}>Endereços Relacionados</Text>
      </View>
      {resultPeapleData ? (
        resultPeapleData.Addresses && resultPeapleData.Addresses.length > 0 ? (
          <>
            {resultPeapleData.Addresses.map((address, index) => {
              return (
                <>
                  <View style={[styles.border, {paddingBottom: 4}]} key={index}>
                    <View style={styles.row}>
                      <View style={styles.groupInfo}>
                        <Text style={[styles.h4, styles.bold]}>
                          Endereço:
                          <Text style={styles.h4}>{address.Alias}</Text>
                        </Text>
                      </View>
                    </View>
                    <View style={styles.row}>
                      <View style={styles.groupInfo}>
                        <Text style={[styles.h4, styles.bold]}>
                          Complemento:
                          <Text style={styles.h4}>{address.ComplementFormatted}</Text>
                        </Text>
                      </View>
                    </View>
                    <View style={styles.row}>
                      <View style={styles.groupInfo}>
                        <Text style={[styles.h4, styles.bold]}>
                          Bairro:
                          <Text style={styles.h4}>{address.District}</Text>
                        </Text>
                      </View>
                    </View>
                    <View style={styles.row}>
                      <View style={styles.groupInfo}>
                        <Text style={[styles.h4, styles.bold]}>
                          Cidade:
                          <Text style={styles.h4}>{address.City}</Text>
                        </Text>
                      </View>
                    </View>
                    <View style={styles.row}>
                      <View style={styles.groupInfo}>
                        <Text style={[styles.h4, styles.bold]}>
                          Estado:
                          <Text style={styles.h4}>{address.State}</Text>
                        </Text>
                      </View>
                    </View>
                  </View>
                </>
              )
            })}
          </>
        ) : (
          <></>
        )
      ) : (
        <></>
      )}
      {result?.addresses_extended ? (
        <AddressesExtended
          data={result.addresses_extended}
          estrutura={dadosTabelas[typeDoc]['AddressesExtended']}
        ></AddressesExtended>
      ) : (
        ''
      )}
    </>
  )
}
