import {ClearText, ColorMapboxToKepler, GetPropert} from '../../../../../common'
// import { IconLayer } from '../IconLayer/IconLayer'
// import LayerComposite from '../LayerComposite'
import {BaseLayer, DefaultLayerProps} from '../base-layer'
import {MVTLayer as DeckMVTLayer} from '@deck.gl/geo-layers'
import {IconLayer, TextLayer, ScatterplotLayer, GeoJsonLayer, PathLayer} from '@deck.gl/layers'
import {Pin, ICON_MAPPING} from '../Icones'
// import {DEFAULT_COLOR} from '../../constants/layers'
import * as turf from '@turf/turf'
export class MVTLayer extends BaseLayer {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(props: DefaultLayerProps) {
    super(props)
  }

  draw(propsInput: DefaultLayerProps) {
    let props = {...this.getDefaultLayerConfig()}
    props = {...propsInput}
    
    // const {zoom} = props.context.viewport
    if (props.composite.length === 0) {
      return [
        new DeckMVTLayer({
          stroked: true,
          id: props.id,
          data: [props.tile],
          highlightColor: props.highlightColor,
          lineWidthMinPixels:
            props.style && typeof props.style['line-width'] !== 'undefined'
              ? props.style['line-width']
              : 1,
          pickable: true,
          pointRadiusScale: 1,
          visible: props.visible,
          getPointRadius: (f: any) => {
            return GetPropert(props.style, 'circle-radius', f.properties) * 5 || 10
          },
          style: props.style,
          filter: props.filter,
          selectedPolygon: props.selectedPolygon,
          binary: false,
          // extruded: true,
          filled: true,
          radiusMinPixels: 6,
          radiusMaxPixels: 10,
          onClick: (info, event) => {
            this.clickEvent(info, event)
          },
          getFillColor: (f: any) => {
            if (
              typeof props.selectedPolygon !== 'undefined' &&
              props.selectedPolygon !== null &&
              props.selectedPolygon.length > 0
            ) {
              const id = f.properties['gid']

              //Alterei aqui de indexof of para find
              if (props.selectedPolygon.find((i) => i === id)) {
                return [50, 167, 82, 80]
              } else {
                let colorFinal = ColorMapboxToKepler(props.style, f.properties, 'fill-color')
                return colorFinal
              }
            } else {
              let colorFinal = ColorMapboxToKepler(props.style, f.properties, 'fill-color')
              return colorFinal || [234, 234, 234, 180]
            }
          },
          getLineColor: async (f: any) => {
            if (props.style && typeof props.style['line-color'] !== 'undefined') {
              let colorFinal = ColorMapboxToKepler(props.style, f.properties, 'line-color') // GetPropert(props.style, 'line-color')
              return colorFinal
            } else {
              return false
            }
          },
          // getLineColor: (f)=>{
          //     console.log('asasdsa')
          //     console.log(props)
          //     console.log(props.style)

          //   if (props.style && typeof props.style['line-color'] !== 'undefined') {
          //     let colorFinal = GetPropert(props.style, 'line-color')
          //     return colorFinal
          //   } else {
          //     return [0,0,0]
          //   }
          // },
          // getLineColor: async (f: any) => {
          //   // console.log(props.style)

          //   // if (props.style && typeof props.style['fill-color'] !== 'undefined') {
          //   //   let colorFinal = await ColorMapboxToKepler(props.style, f.properties, 'fill-color')
          //   //   console.log(colorFinal)
          //   //   return [255,11,11]
          //   // } else {
          //     return [255,11,11]
          //   // }
          // },
          getRadiusSize: () => {
            let value = GetPropert(props.style, 'circle-radius')
            return value || 10
          },
          renderSubLayers: (props) => {
            const {tile} = props
            if (typeof props.filter !== 'undefined' || typeof props.intersect !== 'undefined') {
              if (props.filter !== null || props.intersect !== '') {

               

                props.data = this.getFilterData(
                  props.data,
                  props.filter,
                  props.tipo,
                  props.intersect,
                  tile.dataInWGS84
                )
              }
            }
            return [
              new GeoJsonLayer(props, {
                stroked: true,
                id: props.id + '_geojson',
                data: props.data,
                pickable: true,
                filled: true,
                radiusMinPixels: 6,
                radiusMaxPixels: 20,
                lineWidthMinPixels: props.lineWidthMinPixels,

                // extruded: false,
                // getPointRadius: 4,
                getPointRadius: props.getPointRadius || 10,
                // getText:'teste',
                // lineWidthUnits: "pixels",
                lineWidthScale: 1,
                getLineWidth: () => {
                  let valor = GetPropert(props.style, 'line-width')
                  return valor || 0
                },
                // getPointRadius: props.getRadiusSize,
                getFillColor: props.getFillColor,
                getLineColor: (f) => {
                  if (props.style && typeof props.style['line-color'] !== 'undefined') {
                    let colorFinal = ColorMapboxToKepler(props.style, f.properties, 'line-color')
                    return colorFinal
                  } else {
                    return [0, 0, 0]
                  }
                },
                // getLineWidth: this.getSubLayerAccessor(getLineWidth),
                autoHighlight: true,
                highlightColor: [0, 200, 255, 128],
                updateTriggers: {
                  getFillColor: props.getFillColor,
                  getLineColor: props.getLineColor,
                  getPointRadius: props.getPointRadius || 10,
                  // getRadius: props.getRadiusSize,
                  //   getLineWidth: getLineWidth,
                  //   // getPointRadius: getPointRadius,

                  //   // getElevation: getElevation,
                  // },
                  // getFillColor: props.getFillColor,
                  // getLineColoer: this.getSubLayerAccessor(getLineColor),
                },
              }),
            ]
          },
        }),
      ]
    } else {
      return [
        new DeckMVTLayer({
          id: props.id,
          stroked: true,
          data: [props.tile],
          highlightColor: props.highlightColor,
          lineWidthMinPixels:
            props.style && typeof props.style['line-width'] !== 'undefined'
              ? props.style['line-width']
              : 0,
          pickable: true,
          icon: props.icon,
          pointRadiusScale: 1,
          visible: props.visible,
          filter: props.filter,
          composite: props.composite,
          binary: false,
          label: ClearText(props.label),
          getLineWidth: () => {
            let valor = GetPropert(props.style, 'line-width')
            return valor || 0
          },
          getPosition: (f) => {
            return f.geometry.coordinates
          },
          getSizeIcone: (f: any) => {
            let value = GetPropert(props.style, 'size')
            return value * 10 || 30
          },
          getSizeText: (f: any) => {
            let value = GetPropert(props.style, 'size-text')
            return value || 16
          },
          getColorText: (f: any) => {
            let value = ColorMapboxToKepler(props.style, f.properties, 'text-color')
            return value || [0, 0, 0, 150]
          },
          getText: (f) => {
            // const texts = ClearText(f.properties[props.label])
            const texts = Buffer.from(
              ClearText(f.properties[props.label])
                .replace(' ', '\r\n')
                .replace(' ', '\r\n')
                .replace(' ', '\r\n'),
              'utf-8'
            ).toString()
            return texts
          },
          getRadiusSize: () => {
            let value = GetPropert(props.style, 'circle-radius')
            return value || 10
          },
          onClick: (info, event) => {
            this.clickEvent(info, event)
          },
          getFillColor: (f: any) => {
            if (
              typeof props.selectedPolygon !== 'undefined' &&
              props.selectedPolygon !== null &&
              props.selectedPolygon.length > 0
            ) {
              const id = f.properties['gid']
              if (props.selectedPolygon.find((i) => i === id)) {
                return [50, 167, 82, 80]
              } else {
                let colorFinal = ColorMapboxToKepler(props.style, f.properties, 'fill-color')
                return colorFinal
              }
            } else {
              let colorFinal = ColorMapboxToKepler(props.style, f.properties, 'fill-color')
              return colorFinal
            }
          },
          getLineColor: async (f: any) => {
            if (props.style && typeof props.style['line-color'] !== 'undefined') {
              let colorFinal = GetPropert(props.style, 'line-color')

              return colorFinal
            } else {
              return false
            }
          },
          getLabelSize: (f) => 20,
          getLabel: (f) => {
            return Buffer.from(f.properties[props.label], 'utf-8').toString()
          },
          renderSubLayers: (props) => {
            const {tile} = props
            if (typeof props.filter !== 'undefined' || typeof props.intersect !== 'undefined') {
              if (props.filter !== null || props.intersect !== '') {
                props.data = this.getFilterData(
                  props.data,
                  props.filter,
                  props.tipo,
                  props.intersect,
                  tile.dataInWGS84
                )
              }
            }
            props.data =  [...this.ajusteSobreposicao(props.data)]
            const composite = props.composite
    return [
              composite.find((i) => i === 'GeoJsonLayer')
                ? new GeoJsonLayer(props, {
                    // coordinateSystem: 1,
                    id: 'geom_' + props.id,
                    stroked: true,
                    data: props.data,
                    pickable: true,
                    filled: true,
                    radiusMinPixels: 6,
                    radiusMaxPixels: 20,
                    lineWidthMinPixels: props.lineWidthMinPixels,

                    getPosition: props.getPosition,
                    // getRadius:  this.getSubLayerAccessor(getRadius),
                    getFillColor: props.getFillColor,
                    getLineColor: props.getLineColor,
                    getLineWidth: props.getLineWidth,
                    // autoHighlight: true,
                    // highlightColor: [0, 200, 255, 128],
                    updateTriggers: {
                      getPosition: props.getPosition,
                      getFillColor: props.getFillColor,
                      getLineColor: props.getLineColor,
                      getLineWidth: props.getLineWidth,
                    },
                  })
                : [],
              composite.find((i) => i === 'LayerIcon')
                ? new IconLayer(props, {
                    iconAtlas: Pin,
                    iconMapping: ICON_MAPPING,
                    visible: props.visible,
                    getIcon: (d) => {
                      return typeof props.icon !== 'undefined' ? props.icon : 'marker'
                    },
                    // getIcon: d => {

                    //   if (typeof d.icon !== 'undefined' ){
                    //     return { url: '/img/'+props.icon ,
                    //       x: 0,
                    //       y: 0,
                    //       width: 128,
                    //       height: 128,
                    //       anchorY: 128,
                    //       mask: true,
                    //     }
                    //   }else{
                    //     return { url: '/img/'+props.icon ,
                    //     x: 0,
                    //     y: 0,
                    //     width: 128,
                    //     height: 128,
                    //     anchorY: 128,
                    //     mask: true,
                    //   }
                    //   }
                    // },
                    sizeScale: 1,
                    getSize: props.getSizeIcone,
                    getColor: props.getFillColor,
                    updateTriggers: {
                      getColor: props.getFillColor,
                      getSize: props.getSizeIcone,
                      getPosition: (d: any) => {
                        let value = GetPropert(props.style, 'positionZ')
                        if (typeof d.coordinates === 'undefined') {
                          if (d.geometry.coordinates.length === 2) {
                            d.geometry.coordinates.push(value || 20)
                          } else {
                            d.geometry.coordinates[2] = value || 20
                          }
                          return d.geometry.coordinates
                        } else {
                          if (d.coordinates.length === 2) {
                            d.coordinates.push(value || 20)
                          } else {
                            d.coordinates[2] = value || 20
                          }
                          return d.coordinates
                        }
                      },
                    },
                  })
                : [],
              composite.find((i) => i === 'TextLayer')
                ? new TextLayer(props, {
                    id: 'text_' + props.id,
                    visible: props.visible,
                    // getAngle: 0,
                    sizeMaxPixels: 13,
                    sizeUnits: 'pixels',
                    maxWidth: 50,
                    
                    wordBreak: 'break-all',
                    getPixelOffset: [0, 10],
                    getText: props.getText,
                    // characterSet:'utf-8',
                    getColor: [0, 0, 0],
                    getSize: props.getSizeText,
                    getTextAnchor: 'middle',
                    getAlignmentBaseline: 'top',
                    getPosition: (d) => {
                      const centroid = turf.centerOfMass(d)
                      if (centroid.geometry.coordinates.length === 2) {
                        centroid.geometry.coordinates.push(5)
                      } else {
                        centroid.geometry.coordinates[2] = 5
                      }
                      return centroid.geometry.coordinates
                    },
                    // outlineWidth :1,
                    outlineColor: [0, 0, 0, 150],
                    getBorderColor: [0, 0, 0, 255],
                    updateTriggers: {
                      getText: props.getText,
                      getColor: props.getColorText,
                      getSize: props.getSizeText,
                    },
                  })
                : [],
              composite.find((i) => i === 'ScatterplotLayer')
                ? new ScatterplotLayer(props, {
                    stroked: false,
                    id: 'scatter_' + props.id,
                    data: props.data,
                    visible: props.visible,
                    pickable: true,
                    radiusScale: 5,
                    radiusUnits: 'meters',
                    lineWidthUnits: 'meters',
                    radiusMinPixels: 5,
                    radiusMaxPixels: 20,
                    lineWidthMinPixels: 1,
                    highlightColor: [50, 167, 82, 80],
                    autoHighlight: true,
                    getPosition: (p) => {
                      return p.geometry.coordinates
                    },
                    getPointRadius: props.getRadiusSize,
                    // getPointRadius:  this.getSubLayerAccessor(getRadius),
                    getFillColor: props.getFillColor,
                    getLineColor: props.getLineColor,
                    getLineWidth: 0,
                    getOutlineColor: [0, 0, 0],
                    updateTriggers: {
                      getPointRadius: props.getRadiusSize,
                      getFillColor: props.getFillColor,
                      getLineColor: props.getLineColor,
                      getLineWidth: props.getLineWidth,
                    },
                  })
                : [],

              composite.find((i) => i === 'PathLayer')
                ? new PathLayer(props, {
                    id: 'path_' + props.id,
                    // data,
                    pickable: true,
                    lineBillboard: true,
                    widthScale: 2,
                    widthMinPixels: 2,
                    getPath: (d) => {
                      return d.geometry.coordinates[0]
                    },
                    getColor: props.getLineColor,
                    getWidth: props.getLineWidth,
                    // getLineColor: props.getLineColor
                  })
                : [],
            ]
          },
        }),
      ]
    }
  }
}
