import { gql } from "@apollo/client";

export const LOGIN_GENERATED_API_TOKEN = gql`
mutation Login($usuario: usuarioLogin!) {
  Login(usuario: $usuario) {
      id
      api_token
      cor_background
      cor_fonte
      name
      email
      user_pic
      user_pic_thumb
      tipo_usuario
      perfil
      profile {
        id
        nameprofile
        profile
      }
      produtos {
        id
        nome
      }
      empresa {
        id
        nome
        user_logo_pic
        cor_fonte
        cor_background
        cep
        bairro
        cnpj
        complemento
        email
        endereco
        estado
        nome_fantasia
        numero
        telefone
      }
    }
  }
`
