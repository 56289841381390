import {useContext} from 'react'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import {BiArrowBack} from 'react-icons/bi'
import {FilterContext} from '../context/FilterContext'
import {ExportarEstudo} from '../components/Export/ExportarEstudo'
import {Link, useParams} from 'react-router-dom'
import SaveAutomatic from './saveAutomatic'

export default function MenuTopo({nome}: {nome: string}) {
  const {id: idProjetoURL} = useParams<{id: string}>()
  const {filter,endereco} = useContext(FilterContext)

  return (
    <div
      className='header align-items-stretch w-100'
      style={{borderBottom: '1px solid #eff2f5', backgroundColor: '#fff'}}
    >
      <Navbar expand='lg' className=' ' style={{width: '94%'}}>
        <Container fluid>
          <Navbar.Brand href='#'>
            <h2>Inteligência de Mercado</h2>
            <small>{nome} : {endereco?.endereco_completo || 'Sem endereço'}</small>
            
          </Navbar.Brand>
          <Navbar.Toggle aria-controls='navbarScroll' />
          <Navbar.Collapse id='navbarScroll ' className='justify-content-end'>
            <Nav>
              {filter ? (
                filter.segmentacaoArea !== null && filter.segmentacaoPreco !== null ? (
                  <ExportarEstudo id={0}></ExportarEstudo>
                ) : (
                  <></>
                )
              ) : (
                <></>
              )}
              <SaveAutomatic idParam={parseInt(idProjetoURL)} nome={nome}></SaveAutomatic>
              <Link to='/inteligencia-mercado' className='btn btn-sencondary ms-10'>
                <BiArrowBack></BiArrowBack> Voltar
              </Link>
              {/* </Nav.Link> */}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  )
}
