import {DataContext} from '../../context/DataContext'
import {useContext, useEffect, useState} from 'react'
import {formatDistancia, formatNumber} from '../../../explorer/components/common'
import {HeaderPerformance} from '../Common/HeaderPerformance'
import React from 'react'
import {GetVSOChosen} from '../../../../components/UI/Chosens/GetVSOChosen'
import {BiInfoCircle} from 'react-icons/bi'
import {MaisInfoEmpreendimentos} from '../MaisInfoEmpreendimentos'
import TableContainer from '../../../../components/UI/TableContainer'
import {
  RangeColumnFilter,
  FilterRange,
  DefaultColumnFilter,
  SelectColumnFilter,
  FilterSelect,
} from '../../../../components/UI/TableContainer/filters'
import {MixedChart2} from '../Charts/MixedChart2'
import {median} from 'simple-statistics'
type PropMenu = {
  show: boolean
  id: number
}

type TabelaTotais = {
  acimaCurva: {
    totalUnidades: number
    precoMediano: number[]
    ticketMediano: number[]
    areaPrivativaMediana: number[]
    qtdeComSuites: number
    qtdeSemSuites: number
    modaComVagas: number
    modaSemVagas: number
    umaVaga: number
    duasVagas: number
    tresMaisVagas: number
  }[]
  abaixoCurva: {
    totalUnidades: number
    precoMediano: number[]
    ticketMediano: number[]
    areaPrivativaMediana: number[]
    qtdeComSuites: number
    qtdeSemSuites: number
    modaComVagas: number
    modaSemVagas: number
    umaVaga: number
    duasVagas: number
    tresMaisVagas: number
  }[]
}
export function PerformanceAnaliseConcorrentes() {
  const {data, vsoIdeal, setVsoIdeal} = useContext(DataContext)
  const [chartData, setChartData] = useState([])
  const [tableData, setTableData] = useState([])
  const [tabelaTotais, setTabelaTotais] = useState<TabelaTotais>(null)
  const [filtroDormitorios, setFiltroDormitorios] = useState([1])
  const [filterText, setFilterText] = React.useState('')
  const [show, setShow] = useState<PropMenu>({
    show: false,
    id: null,
  })

  const dormitoriosAtivos = []

  function checkCurvaAcima(row) {

    
    let fase = Math.round((Number(row.fase_projeto) * 36) / 100)
    
    if (row.vso >= vsoIdeal[fase].vso) {
      return true
    }
    fase = Math.round((Number(row.fase_projeto) * 36) / 100)
    if (row.vso < vsoIdeal[fase].vso) {
      return false
    }
  }

  useEffect(() => {
    if (data) {
      let chartDataTemp = []
      let tableDataTemp = []

      let totalUnidadesAcimaCurva = []
      let totalUnidadesAbaixoCurva = []

      let chartSeries = {
        series: [],
      }

      let chartDataTemp2 = []
      data.filter((item) => {
       
        item.tipologias.filter((itemTipologia) => {
          if (itemTipologia.computed) {
            if (typeof chartDataTemp2[Number(itemTipologia.bedrooms)] === 'undefined') {
              chartDataTemp2[Number(itemTipologia.bedrooms)] = []
            }
            chartDataTemp2[Number(itemTipologia.bedrooms)].push({
              x: Number(item.fase_projeto),
              y: Number(itemTipologia.vendas_perc_tip),
              id: itemTipologia.id_tipologia,
              nome: item.nome,
              perc_vendas: itemTipologia.vendas_perc_tip,
              total_unidades: itemTipologia.total_units,
              tipologia: itemTipologia.type,
              situacao: item.situacao,
              dormitorios: itemTipologia.bedrooms,
            })

            if (filtroDormitorios.indexOf(Number(itemTipologia.bedrooms)) !== -1) {
              if (
                checkCurvaAcima({
                  vso: itemTipologia.vendas_perc_tip,
                  fase_projeto: item.fase_projeto,
                })
              ) {
                if (typeof totalUnidadesAcimaCurva[itemTipologia.bedrooms] === 'undefined') {
                  totalUnidadesAcimaCurva[itemTipologia.bedrooms] = {
                    totalUnidades: 0,
                    precoMediano: [],
                    ticketMediano: [],
                    areaPrivativaMediana: [],
                    qtdeComSuites: 0,
                    qtdeSemSuites: 0,
                    modaComVagas: 0,
                    modaSemVagas: 0,
                    umaVaga: 0,
                    duasVagas: 0,
                    tresMaisVagas: 0,
                  }
                }

                totalUnidadesAcimaCurva[Number(itemTipologia.bedrooms)].totalUnidades += Number(
                  itemTipologia.total_units
                )

                if (Number(itemTipologia.original_price) > 0) {
                  totalUnidadesAcimaCurva[itemTipologia.bedrooms].precoMediano.push(
                    Number(itemTipologia.original_price) / Number(itemTipologia.private_area)
                  )
                }
                if (Number(itemTipologia.original_price) > 0) {
                  totalUnidadesAcimaCurva[itemTipologia.bedrooms].ticketMediano.push(
                    Number(itemTipologia.original_price)
                  )
                }
                if (Number(itemTipologia.private_area) > 0) {
                  totalUnidadesAcimaCurva[itemTipologia.bedrooms].areaPrivativaMediana.push(
                    Number(itemTipologia.private_area)
                  )
                }
                totalUnidadesAcimaCurva[itemTipologia.bedrooms].modaComVagas  += itemTipologia.parking  >   0 ? Number(itemTipologia.total_units) : 0
                totalUnidadesAcimaCurva[itemTipologia.bedrooms].modaSemVagas  += itemTipologia.parking  <=  0 ? Number(itemTipologia.total_units) : 0
                totalUnidadesAcimaCurva[itemTipologia.bedrooms].qtdeComSuites += itemTipologia.suites   >   0 ? itemTipologia.total_units : 0
                totalUnidadesAcimaCurva[itemTipologia.bedrooms].qtdeSemSuites += itemTipologia.suites   === 0 ? Number(itemTipologia.total_units) : 0
                totalUnidadesAcimaCurva[itemTipologia.bedrooms].umaVaga       +=  itemTipologia.parking === 1 ? Number(itemTipologia.total_units) : 0
                totalUnidadesAcimaCurva[itemTipologia.bedrooms].duasVagas     += itemTipologia.parking  === 2 ? Number(itemTipologia.total_units) : 0
                totalUnidadesAcimaCurva[itemTipologia.bedrooms].tresMaisVagas += itemTipologia.parking  >=  3 ? Number(itemTipologia.total_units) : 0
              } else {
                if (typeof totalUnidadesAbaixoCurva[itemTipologia.bedrooms] === 'undefined') {
                  totalUnidadesAbaixoCurva[itemTipologia.bedrooms] = {
                    totalUnidades: 0,
                    precoMediano: [],
                    ticketMediano: [],
                    areaPrivativaMediana: [],
                    qtdeComSuites: 0,
                    qtdeSemSuites: 0,
                    modaComVagas: 0,
                    modaSemVagas: 0,
                    umaVaga: 0,
                    duasVagas: 0,
                    tresMaisVagas: 0,
                  }
                }
                totalUnidadesAbaixoCurva[Number(itemTipologia.bedrooms)].totalUnidades += Number(
                  itemTipologia.total_units
                )
                if (Number(itemTipologia.original_price) > 0) {
                  totalUnidadesAbaixoCurva[itemTipologia.bedrooms].precoMediano.push(
                    Number(itemTipologia.original_price) / Number(itemTipologia.private_area)
                  )
                }
                if (Number(itemTipologia.original_price) > 0) {
                  totalUnidadesAbaixoCurva[itemTipologia.bedrooms].ticketMediano.push(
                    Number(itemTipologia.original_price)
                  )
                }
                if (Number(itemTipologia.private_area) > 0) {
                  totalUnidadesAbaixoCurva[itemTipologia.bedrooms].areaPrivativaMediana.push(
                    Number(itemTipologia.private_area)
                  )
                }
                
                totalUnidadesAbaixoCurva[itemTipologia.bedrooms].modaComVagas   += itemTipologia.parking  >   0 ? Number(itemTipologia.total_units) : 0
                totalUnidadesAbaixoCurva[itemTipologia.bedrooms].modaSemVagas   += itemTipologia.parking  <=  0 ? Number(itemTipologia.total_units) : 0
                totalUnidadesAbaixoCurva[itemTipologia.bedrooms].qtdeComSuites  += itemTipologia.suites   >   0 ? Number(itemTipologia.total_units) : 0
                totalUnidadesAbaixoCurva[itemTipologia.bedrooms].qtdeSemSuites  += itemTipologia.suites   === 0 ? Number(itemTipologia.total_units) : 0
                totalUnidadesAbaixoCurva[itemTipologia.bedrooms].umaVaga        += itemTipologia.parking  === 1 ? Number(itemTipologia.total_units) : 0
                totalUnidadesAbaixoCurva[itemTipologia.bedrooms].duasVagas      += itemTipologia.parking  === 2 ? Number(itemTipologia.total_units) : 0
                totalUnidadesAbaixoCurva[itemTipologia.bedrooms].tresMaisVagas  += itemTipologia.parking  >=  3 ? Number(itemTipologia.total_units) : 0
              }

              dormitoriosAtivos.push(Number(itemTipologia.bedrooms))
              chartDataTemp.push({
                x: Number(item.fase_projeto),
                y: Number(itemTipologia.vendas_perc_tip),
                id: itemTipologia.id_tipologia,
                nome: item.nome,
                perc_vendas: itemTipologia.vendas_perc_tip,
                total_unidades: itemTipologia.total_units,
                situacao: item.situacao,
                dormitorios: itemTipologia.bedrooms,
              })
              tableDataTemp.push({
                id: itemTipologia.id_tipologia,
                id_empreendimento: item.id_empreendimento,
                id_empreendimento_orulo: item.id_empreendimento_orulo,
                nome: item.nome,
                incorporadora: item.nome_incorporador,
                endereco: item.endereco,
                data_lancamento: item.data_lancamento,
                area_privativa: itemTipologia.private_area,
                total_unidades: itemTipologia.total_units,
                vso: itemTipologia.vendas_perc_tip,
                estoque: itemTipologia.stock,
                dormitorios: itemTipologia.bedrooms,
                vagas: itemTipologia.parking,
                preco_m2: itemTipologia.original_price / itemTipologia.private_area,
                preco_total: itemTipologia.original_price,
                bairro: item.bairro,
                distancia: item.distancia,
              })
              return itemTipologia
            }
          }
          return false
        })

        return item
      })

      // eslint-disable-next-line array-callback-return
      chartDataTemp2.map((item, index) => {
        chartSeries.series.push({
          name: '' + index + ' Dorm.',
          dormitorios: index,
          type: 'scatter',
          data: item,
          visible: filtroDormitorios.indexOf(index) !== -1 ? true : false,
        })
      })
      setChartData(chartSeries.series)
      setTableData(tableDataTemp)
      setTabelaTotais({
        ...{abaixoCurva: totalUnidadesAbaixoCurva, acimaCurva: totalUnidadesAcimaCurva},
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, filtroDormitorios,vsoIdeal])

  const columns = [
    {
      Header: () => null,
      id: 'expander', // 'id' is required
      Cell: ({row}) => {
        return (
          <button
            onClick={() =>
              setShow({
                show: true,
                id: row.original.id_empreendimento_orulo,
              })
            }
            className='btn btn-icon-info btn-text-info'
          >
            <BiInfoCircle className='fs-1'></BiInfoCircle>
          </button>
        )
      },
    },
    {
      Header: 'ID',
      accessor: 'id',
      Filter: (row) => {
        return DefaultColumnFilter(row, filterText)
      },
    },
    {
      Header: 'Nome',
      accessor: 'nome',
      Filter: SelectColumnFilter,
      filter: FilterSelect,
    },
    {
      Header: 'Incorporadora',
      accessor: 'incorporadora',
      Filter: SelectColumnFilter,
      filter: FilterSelect,
    },

    {
      Header: 'Endereço',
      accessor: 'endereco',
    },
    {
      Header: 'Bairro',
      accessor: 'bairro',
    },
    {
      Header: 'Data Lanç.',
      accessor: 'data_lancamento',
      sortType: (rowA, rowB, columnId, desc) => {
        var dateString = rowA.values[columnId] // Oct 23
        var dateParts = dateString.split('/')
        var dateObjectA = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0])

        var dateStringB = rowB.values[columnId] // Oct 23
        var datePartsB = dateStringB.split('/')
        var dateObjectB = new Date(+datePartsB[2], datePartsB[1] - 1, +datePartsB[0])

        // const dateA = new Date(rowA.values[columnId]);

        // const dateB = new Date(rowB.values[columnId]);
        return dateObjectA > dateObjectB ? 1 : -1
      },
    },
    {
      Header: () => <div style={{textAlign: 'center'}}>Dormitórios</div>,
      accessor: 'dormitorios',
      Cell: ({row}) => (
        <div style={{textAlign: 'right'}}> {formatNumber(row.original.dormitorios, 'inteiro')}</div>
      ),
      Filter: RangeColumnFilter,
      filter: FilterRange,
    },
    {
      Header: () => <div style={{textAlign: 'center'}}>Área Privativa</div>,
      accessor: 'area_privativa',
      Cell: ({row}) => (
        <div style={{textAlign: 'right'}}>
          {' '}
          {formatNumber(row.original.area_privativa, 'inteiro')}
        </div>
      ),
      Filter: RangeColumnFilter,
      filter: FilterRange,
    },
    {
      Header: () => <div style={{textAlign: 'center'}}>Total unidades</div>,
      accessor: 'total_unidades',
      Cell: ({row}) => (
        <div style={{textAlign: 'right'}}>
          {' '}
          {formatNumber(row.original.total_unidades, 'inteiro')}
        </div>
      ),
      Filter: RangeColumnFilter,
      filter: FilterRange,
    },
    {
      Header: () => <div style={{textAlign: 'center'}}>VSO</div>,
      accessor: 'vso',
      Cell: ({row}) => (
        <div style={{textAlign: 'right'}}> {formatNumber(row.original.vso, 'inteiro')}</div>
      ),
      Filter: RangeColumnFilter,
      filter: FilterRange,
    },
    {
      Header: () => <div style={{textAlign: 'center'}}>Estoque</div>,
      accessor: 'estoque',
      Cell: ({row}) => <div style={{textAlign: 'center'}}> {row.original.estoque}</div>,
      Filter: RangeColumnFilter,
      filter: FilterRange,
    },
    {
      Header: () => <div style={{textAlign: 'center'}}>Vagas</div>,
      accessor: 'vagas',
      Cell: ({row}) => <div style={{textAlign: 'center'}}> {row.original.vagas}</div>,
      Filter: RangeColumnFilter,
      filter: FilterRange,
    },
    {
      Header: () => <div style={{textAlign: 'center'}}>Preço m²</div>,
      accessor: 'preco_m2',
      Cell: ({row}) => (
        <div style={{textAlign: 'right'}}> {formatNumber(row.original.preco_m2, 'currency')}</div>
      ),
      Filter: RangeColumnFilter,
      filter: FilterRange,
    },
    {
      Header: () => <div style={{textAlign: 'center'}}>Preço Total</div>,
      accessor: 'preco_total',
      Cell: ({row}) => {
        return (
          <div style={{textAlign: 'right'}}>
            {' '}
            {formatNumber(row.original.preco_total, 'currency')}
          </div>
        )
      },
      Filter: RangeColumnFilter,
      filter: FilterRange,
      className: 'text-center',
    },
    {
      Header: () => <div style={{textAlign: 'center'}}>Distância</div>,
      accessor: 'distancia',
      Cell: ({row}) => {
        return <div style={{textAlign: 'center'}}>{formatDistancia(row.original.distancia)}</div>
      },
      Filter: RangeColumnFilter,
      filter: FilterRange,
    },
  ]

  if (!data) {
    return <>Carregando..</>
  }

  const toggleDormitorio = (numDormitorio) => {
    setFiltroDormitorios((prev) => {
      if (prev.includes(numDormitorio)) {
        return prev.filter((dormitorio) => dormitorio !== numDormitorio)
      } else {
        return [...prev, numDormitorio]
      }
    })
  }

  function onChangeDormitorio(dorm: number) {
    setFilterText('')
    toggleDormitorio(dorm)
  }

  return (
    <>
      <div className=' d-flex flex-column justify-content-start p-10'>
        <HeaderPerformance titulo={'Análise Concorrentes'}>
          <div className='pull-left w-300px d-flex align-content-center justify-content-center'>
            <div className='col-4 pt-3'>VSO</div>
            <div className='col-8'>
              <GetVSOChosen
                idVso={0}
                setValue={(value) => {
                  setVsoIdeal(value)
                }}
              ></GetVSOChosen>
            </div>
          </div>
        </HeaderPerformance>

        <div className='content pt-10'>
          <div className='row'>
            <div className='col-12'>
              <MixedChart2
                data={chartData}
                onChangeDormitorio={onChangeDormitorio}
                className={'bgi-no-repeat card-xl-stretch mb-xl-8 border-2 box-performance mb-10'}
                title={'Desempenho de Vendas Real'}
                subtitle={'Vendas Real x Curva Ideal de Vendas'}
                onChange={(id: number) => {
                  setFilterText(id + '')
                }}
                vsoIdeal={vsoIdeal}
              >
                {tabelaTotais ? (
                  <div className='p-10'>
                    <div className='alert alert-info w-100'>
                      <b>Atenção:</b> Produtos com estoque e 100% vendidos.
                    </div>
                    <table className='table table-striped  gs-4  border border-gray-300 border-start-solid'>
                      <thead>
                        <tr className='fw-bold fs-6 text-gray-800'>
                          <th></th>
                          {filtroDormitorios.map((dormitorios, index) => {
                            return (
                              <th
                                colSpan={2}
                                key={dormitorios}
                                className='text-center border  border-gray-300 border-end-dashed'
                              >
                                {dormitorios} Dormitório
                              </th>
                            )
                          })}
                        </tr>
                        <tr className='fw-bold fs-6 text-gray-800'>
                          <th></th>

                          {filtroDormitorios.map((dormitorios, index) => {
                            return (
                              <>
                                <th className='text-center border border-gray-300 border-start-solid'>
                                  Acima
                                </th>
                                <th className='text-center border border-gray-300 border-start-solid'>
                                  Abaixo
                                </th>
                              </>
                            )
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className='border border-gray-300 border-start-solid'>
                            Total Unidades
                          </td>
                          {filtroDormitorios.map((dormitorios, index) => {
                            return tabelaTotais ? (
                              <>
                                {typeof tabelaTotais.acimaCurva[dormitorios] !== 'undefined' ? (
                                  <td
                                    key={'col1' + index}
                                    className='text-center border border-gray-300 border-start-solid'
                                  >
                                    {tabelaTotais.acimaCurva[dormitorios].totalUnidades}
                                  </td>
                                ) : (
                                  <td className='text-center border border-gray-300 border-start-solid'>
                                    -
                                  </td>
                                )}
                                {typeof tabelaTotais.abaixoCurva[dormitorios] !== 'undefined' ? (
                                  <td
                                    key={'col2' + index}
                                    className='text-center border border-gray-300 border-start-solid'
                                  >
                                    {tabelaTotais.abaixoCurva[dormitorios].totalUnidades}
                                  </td>
                                ) : (
                                  <td className='text-center border border-gray-300 border-start-solid'>
                                    -
                                  </td>
                                )}
                              </>
                            ) : (
                              <>-</>
                            )
                          })}
                        </tr>
                        <tr>
                          <td className='border border-gray-300 border-start-solid'>
                            Preço M² Mediano
                          </td>
                          {filtroDormitorios.map((dormitorios, index) => {
                            return tabelaTotais ? (
                              <>
                                {typeof tabelaTotais.acimaCurva[dormitorios] !== 'undefined' ? (
                                  <td
                                    key={'col1' + index}
                                    className='text-center border border-gray-300 border-start-solid'
                                  >
                                    {tabelaTotais.acimaCurva[dormitorios].precoMediano.length > 0
                                      ? formatNumber(
                                          median(tabelaTotais.acimaCurva[dormitorios].precoMediano),
                                          'currency'
                                        )
                                      : ''}
                                  </td>
                                ) : (
                                  <td className='text-center border border-gray-300 border-start-solid'>
                                    -
                                  </td>
                                )}
                                {typeof tabelaTotais.abaixoCurva[dormitorios] !== 'undefined' ? (
                                  <td
                                    key={'col2' + index}
                                    className='text-center border border-gray-300 border-start-solid'
                                  >
                                    {tabelaTotais.abaixoCurva[dormitorios].precoMediano.length > 0
                                      ? formatNumber(
                                          median(
                                            tabelaTotais.abaixoCurva[dormitorios].precoMediano
                                          ),
                                          'currency'
                                        )
                                      : ''}
                                  </td>
                                ) : (
                                  <td className='text-center border border-gray-300 border-start-solid'>
                                    -
                                  </td>
                                )}
                              </>
                            ) : (
                              <></>
                            )
                          })}
                        </tr>
                        <tr>
                          <td className='border border-gray-300 border-start-solid'>
                            Ticket Mediano
                          </td>
                          {filtroDormitorios.map((dormitorios, index) => {
                            return tabelaTotais ? (
                              <>
                                {typeof tabelaTotais.acimaCurva[dormitorios] !== 'undefined' ? (
                                  <td
                                    key={'col1' + index}
                                    className='text-center border border-gray-300 border-start-solid'
                                  >
                                    {tabelaTotais.acimaCurva[dormitorios].ticketMediano.length > 0
                                      ? formatNumber(
                                          median(
                                            tabelaTotais.acimaCurva[dormitorios].ticketMediano
                                          ),
                                          'currency'
                                        )
                                      : ''}
                                  </td>
                                ) : (
                                  <td className='text-center border border-gray-300 border-start-solid'>
                                    -
                                  </td>
                                )}
                                {typeof tabelaTotais.abaixoCurva[dormitorios] !== 'undefined' ? (
                                  <td
                                    key={'col2' + index}
                                    className='text-center border border-gray-300 border-start-solid'
                                  >
                                    {tabelaTotais.abaixoCurva[dormitorios].ticketMediano.length > 0
                                      ? formatNumber(
                                          median(
                                            tabelaTotais.abaixoCurva[dormitorios].ticketMediano
                                          ),
                                          'currency'
                                        )
                                      : ''}
                                  </td>
                                ) : (
                                  <td className='text-center border border-gray-300 border-start-solid'>
                                    -
                                  </td>
                                )}
                              </>
                            ) : (
                              <></>
                            )
                          })}
                        </tr>
                        <tr>
                          <td className='border border-gray-300 border-start-solid'>
                            Área privativa mediana
                          </td>
                          {filtroDormitorios.map((dormitorios, index) => {
                            return tabelaTotais ? (
                              <>
                                {typeof tabelaTotais.acimaCurva[dormitorios] !== 'undefined' ? (
                                  <td
                                    key={'col1' + index}
                                    className='text-center border border-gray-300 border-start-solid'
                                  >
                                    {tabelaTotais.acimaCurva[dormitorios].areaPrivativaMediana
                                      .length > 0
                                      ? formatNumber(median(
                                          tabelaTotais.acimaCurva[dormitorios].areaPrivativaMediana
                                        ),'inteiro')
                                      : ''}
                                  </td>
                                ) : (
                                  <td className='text-center border border-gray-300 border-start-solid'>
                                    -
                                  </td>
                                )}
                                {typeof tabelaTotais.abaixoCurva[dormitorios] !== 'undefined' ? (
                                  <td
                                    key={'col2' + index}
                                    className='text-center border border-gray-300 border-start-solid'
                                  >
                                    {tabelaTotais.abaixoCurva[dormitorios].areaPrivativaMediana
                                      .length > 0
                                      ? median(
                                          tabelaTotais.abaixoCurva[dormitorios].areaPrivativaMediana
                                        )
                                      : ''}
                                  </td>
                                ) : (
                                  <td className='text-center border border-gray-300 border-start-solid'>
                                    -
                                  </td>
                                )}
                              </>
                            ) : (
                              <></>
                            )
                          })}
                        </tr>
                        <tr>
                          <td className='border border-gray-300 border-start-solid'>
                            Qtde de produtos <b>COM</b> Suítes
                          </td>
                          {filtroDormitorios.map((dormitorios, index) => {
                            return tabelaTotais ? (
                              <>
                                {typeof tabelaTotais.acimaCurva[dormitorios] !== 'undefined' ? (
                                  <td
                                    key={'col1' + index}
                                    className='text-center border border-gray-300 border-start-solid'
                                  >
                                    {tabelaTotais.acimaCurva[dormitorios].qtdeComSuites}
                                  </td>
                                ) : (
                                  <td className='text-center border border-gray-300 border-start-solid'>
                                    -
                                  </td>
                                )}
                                {typeof tabelaTotais.abaixoCurva[dormitorios] !== 'undefined' ? (
                                  <td
                                    key={'col2' + index}
                                    className='text-center border border-gray-300 border-start-solid'
                                  >
                                    {tabelaTotais.abaixoCurva[dormitorios].qtdeComSuites}
                                  </td>
                                ) : (
                                  <td className='text-center border border-gray-300 border-start-solid'>
                                    -
                                  </td>
                                )}
                              </>
                            ) : (
                              <></>
                            )
                          })}
                        </tr>
                        <tr>
                          <td className='border border-gray-300 border-start-solid'>
                            Qtde de produtos <b>SEM</b> Suítes
                          </td>
                          {filtroDormitorios.map((dormitorios, index) => {
                            return tabelaTotais ? (
                              <>
                                {typeof tabelaTotais.acimaCurva[dormitorios] !== 'undefined' ? (
                                  <td
                                    key={'col1' + index}
                                    className='text-center border border-gray-300 border-start-solid'
                                  >
                                    {tabelaTotais.acimaCurva[dormitorios].qtdeSemSuites}
                                  </td>
                                ) : (
                                  <td className='text-center border border-gray-300 border-start-solid'>
                                    -
                                  </td>
                                )}
                                {typeof tabelaTotais.abaixoCurva[dormitorios] !== 'undefined' ? (
                                  <td
                                    key={'col2' + index}
                                    className='text-center border border-gray-300 border-start-solid'
                                  >
                                    {tabelaTotais.abaixoCurva[dormitorios].qtdeSemSuites}
                                  </td>
                                ) : (
                                  <td className='text-center border border-gray-300 border-start-solid'>
                                    -
                                  </td>
                                )}
                              </>
                            ) : (
                              <></>
                            )
                          })}
                        </tr>
                        
                        <tr>
                          <td className='border border-gray-300 border-start-solid'>
                            Qtde de produtos sem vagas
                          </td>
                          {filtroDormitorios.map((dormitorios, index) => {
                            return tabelaTotais ? (
                              <>
                                {typeof tabelaTotais.acimaCurva[dormitorios] !== 'undefined' ? (
                                  <td
                                    key={'col1' + index}
                                    className='text-center border border-gray-300 border-start-solid'
                                  >
                                    {tabelaTotais.acimaCurva[dormitorios].modaSemVagas}
                                  </td>
                                ) : (
                                  <td className='text-center border border-gray-300 border-start-solid'>
                                    -
                                  </td>
                                )}
                                {typeof tabelaTotais.abaixoCurva[dormitorios] !== 'undefined' ? (
                                  <td
                                    key={'col2' + index}
                                    className='text-center border border-gray-300 border-start-solid'
                                  >
                                    {tabelaTotais.abaixoCurva[dormitorios].modaSemVagas}
                                  </td>
                                ) : (
                                  <td className='text-center border border-gray-300 border-start-solid'>
                                    -
                                  </td>
                                )}
                              </>
                            ) : (
                              <></>
                            )
                          })}
                        </tr>
                        <tr>
                          <td className='border border-gray-300 border-start-solid'>
                            Qtde de produtos com vagas
                          </td>
                          {filtroDormitorios.map((dormitorios, index) => {
                            return tabelaTotais ? (
                              <>
                                {typeof tabelaTotais.acimaCurva[dormitorios] !== 'undefined' ? (
                                  <td
                                    key={'col1' + index}
                                    className='text-center border border-gray-300 border-start-solid'
                                  >
                                    {tabelaTotais.acimaCurva[dormitorios].modaComVagas}
                                  </td>
                                ) : (
                                  <td className='text-center border border-gray-300 border-start-solid'>
                                    -
                                  </td>
                                )}
                                {typeof tabelaTotais.abaixoCurva[dormitorios] !== 'undefined' ? (
                                  <td
                                    key={'col2' + index}
                                    className='text-center border border-gray-300 border-start-solid'
                                  >
                                    {tabelaTotais.abaixoCurva[dormitorios].modaComVagas}
                                  </td>
                                ) : (
                                  <td className='text-center border border-gray-300 border-start-solid'>
                                    -
                                  </td>
                                )}
                              </>
                            ) : (
                              <></>
                            )
                          })}
                        </tr>
                        <tr>
                          <td className='border border-gray-300 border-start-solid'>
                            Qtde de produtos com 1 vaga
                          </td>
                          {filtroDormitorios.map((dormitorios, index) => {
                            return tabelaTotais ? (
                              <>
                                {typeof tabelaTotais.acimaCurva[dormitorios] !== 'undefined' ? (
                                  <td
                                    key={'col1' + index}
                                    className='text-center border border-gray-300 border-start-solid'
                                  >
                                    {tabelaTotais.acimaCurva[dormitorios].umaVaga}
                                  </td>
                                ) : (
                                  <td className='text-center border border-gray-300 border-start-solid'>
                                    -
                                  </td>
                                )}
                                {typeof tabelaTotais.abaixoCurva[dormitorios] !== 'undefined' ? (
                                  <td
                                    key={'col2' + index}
                                    className='text-center border border-gray-300 border-start-solid'
                                  >
                                    {tabelaTotais.abaixoCurva[dormitorios].umaVaga}
                                  </td>
                                ) : (
                                  <td className='text-center border border-gray-300 border-start-solid'>
                                    -
                                  </td>
                                )}
                              </>
                            ) : (
                              <></>
                            )
                          })}
                        </tr>
                        <tr>
                          <td className='border border-gray-300 border-start-solid'>
                            Qtde de produtos com 2 vagas
                          </td>
                          {filtroDormitorios.map((dormitorios, index) => {
                            return tabelaTotais ? (
                              <>
                                {typeof tabelaTotais.acimaCurva[dormitorios] !== 'undefined' ? (
                                  <td
                                    key={'col1' + index}
                                    className='text-center border border-gray-300 border-start-solid'
                                  >
                                    {tabelaTotais.acimaCurva[dormitorios].duasVagas}
                                  </td>
                                ) : (
                                  <td className='text-center border border-gray-300 border-start-solid'>
                                    -
                                  </td>
                                )}
                                {typeof tabelaTotais.abaixoCurva[dormitorios] !== 'undefined' ? (
                                  <td
                                    key={'col2' + index}
                                    className='text-center border border-gray-300 border-start-solid'
                                  >
                                    {tabelaTotais.abaixoCurva[dormitorios].duasVagas}
                                  </td>
                                ) : (
                                  <td className='text-center border border-gray-300 border-start-solid'>
                                    -
                                  </td>
                                )}
                              </>
                            ) : (
                              <></>
                            )
                          })}
                        </tr>
                        <tr>
                          <td className='border border-gray-300 border-start-solid'>
                            Qtde de produtos com 3 ou mais vagas
                          </td>
                          {filtroDormitorios.map((dormitorios, index) => {
                            return tabelaTotais ? (
                              <>
                                {typeof tabelaTotais.acimaCurva[dormitorios] !== 'undefined' ? (
                                  <td
                                    key={'col1' + index}
                                    className='text-center border border-gray-300 border-start-solid'
                                  >
                                    {tabelaTotais.acimaCurva[dormitorios].tresMaisVagas}
                                  </td>
                                ) : (
                                  <td className='text-center border border-gray-300 border-start-solid'>
                                    -
                                  </td>
                                )}
                                {typeof tabelaTotais.abaixoCurva[dormitorios] !== 'undefined' ? (
                                  <td
                                    key={'col2' + index}
                                    className='text-center border border-gray-300 border-start-solid'
                                  >
                                    {tabelaTotais.abaixoCurva[dormitorios].tresMaisVagas}
                                  </td>
                                ) : (
                                  <td className='text-center border border-gray-300 border-start-solid'>
                                    -
                                  </td>
                                )}
                              </>
                            ) : (
                              <></>
                            )
                          })}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <></>
                )}
                <TableContainer
                  columns={columns}
                  data={tableData}
                  filter={filterText}
                ></TableContainer>
              </MixedChart2>
            </div>
          </div>
        </div>
      </div>
      <MaisInfoEmpreendimentos
        setShow={setShow}
        show={show}
        height={'calc(100vh)'}
      ></MaisInfoEmpreendimentos>
    </>
  )
}
